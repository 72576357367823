import Css from "./style.module.scss";

import fallbackLogoSrc from "assets/business.svg";

import { AutoTooltip } from "lib/common";
import { TableCell } from "lib/common/TableNew";
import { getProjectName } from "selectors/organizations";
import { useDispatch, useSelector } from "react-redux";
import BusinessStatus from "nlib/common/BusinessStatus";
import IntegrationServices from "const/IntegrationServices";
import IntegrationsActions from "actions/IntegrationsActions";
import React, { useCallback } from "react";
import UiActions from "actions/UiActions";

const NameCell = ({ className, name, businessId, extraData, settings }) => {
  const { integrationService } = extraData;

  const dispatch = useDispatch();

  const projectName = useSelector(getProjectName);

  const {
    logo: serviceLogo,
    label: serviceName
  } = IntegrationServices.getByValue(integrationService)
    || { logo: fallbackLogoSrc, label: projectName };

  const handleReconnectClick = useCallback(async(event) => {
    event.preventDefault();

    dispatch(UiActions.togglePreloader(true));

    const quickBooksDesktopBusiness = integrationService === IntegrationServices.QUICK_BOOKS_DESKTOP.value;

    if (quickBooksDesktopBusiness) {
      window.location.href = `/${businessId}`;
    } else {
      const consentUrl = await dispatch(IntegrationsActions.buildConsentUrl(integrationService));

      dispatch(UiActions.togglePreloader(false));
      if (consentUrl) window.location.href = consentUrl;
    }
  }, [businessId, dispatch, integrationService]);

  return (
    <TableCell className={`${Css.nameCell} ${className}`}>
      <a href={`/${businessId}`}>
        <img title={serviceName} alt={serviceName} src={serviceLogo} />
        <div className={Css.content}>
          <AutoTooltip title={name}>
            <div className={Css.name}>
              <span>{name}</span>
            </div>
          </AutoTooltip>
          <BusinessStatus
            extraData={extraData}
            settings={settings}
            onReconnectClick={handleReconnectClick} />
        </div>
      </a>
    </TableCell>
  );
};

export default React.memo(NameCell);
